import React, { useState, useEffect } from 'react';
import './Career.css'; // Ensure you have imported the CSS
import '../../App.css';

const Career = () => {
  const [jobs, setJobs] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/career-list/jobs.txt')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then((text) => {
        const lines = text.trim().split('\n');
        const jobsData = lines.map((line, index) => {
          const parts = line.split(';');
          const [title, company, duration, ...bulletPoints] = parts.map(part => part.trim());
          return { id: index, title, company, duration, bulletPoints }; // Assuming no unique ID is available
        });
        setJobs(jobsData);
      })
      .catch((error) => {
        setError(error.message);
      });
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="career-container">
      <h2 className="text-center mb-4">Career History</h2>
      {jobs.map((job) => (
        <div key={job.id} className="card mb-3">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0">{job.title} at {job.company}</h5>
              <span className='job-duration'>{job.duration}</span>
            </div>
            <ul className="mt-3">
              {job.bulletPoints.map((point, idx) => (
                <li key={idx}>{point}</li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Career;
  