import React, { useState, useEffect } from 'react';
// Import Swiper React components and the core Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper/modules';
import SwiperCore from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';
import '../../App.css';
import './AboutMe.css';

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade]);

export default function AboutMe() {
  const [slides, setSlides] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Dynamically fetch slide data
  useEffect(() => {
    async function fetchSlides() {
      try {
        // Simulate an API call or fetch local data
        const response = await fetch('/data/slides.json'); // Adjust the path to your data file
        const data = await response.json();
        setSlides(data);
      } catch (error) {
        console.error('Error fetching slides:', error);
      }
    }
    fetchSlides();
  }, []);

  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.realIndex);
  };

  const getNextSlide = () => slides[(currentIndex + 1) % slides.length];
  const getPrevSlide = () => slides[(currentIndex - 1 + slides.length) % slides.length];

  return (
    <div className="aboutme-container">
      <h1>About Me</h1>
      <div className="content-container">
        {/* Carousel Section */}
        {slides.length > 0 && (
          <div className="carousel-container">
            <Swiper
              className="mySwiper"
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade]}
              spaceBetween={30}
              slidesPerView={1}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
              pagination={{ clickable: true }}
              autoplay={{ delay: 3000, disableOnInteraction: false }}
              loop={true}
              effect="fade"
              onSlideChange={handleSlideChange}
            >
              {slides.map((slide, index) => (
                <SwiperSlide key={index}>
                  <div className="slide-container">
                    <img src={slide.url} alt={`Slide ${index}`} />
                    <div className="slide-caption">{slide.caption}</div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div
              className="swiper-button-next"
              style={{ backgroundImage: `url(${getNextSlide()?.url || ''})` }}
            >
              <i className={`bi ${getNextSlide()?.icon || ''}`}></i>
            </div>
            <div
              className="swiper-button-prev"
              style={{ backgroundImage: `url(${getPrevSlide()?.url || ''})` }}
            >
              <i className={`bi ${getPrevSlide()?.icon || ''}`}></i>
            </div>
          </div>
        )}

        {/* Text Section */}
        <div className="text-container">
          <p>
            Thanks for visiting my website! I’m an electrical engineer on paper, but at heart, 
            I’m someone who thrives on <span className="highlight">solving complex problems</span> and <span className="highlight">learning new things</span>. 
            Whether it’s mastering software tools or tackling a challenging project, 
            I’m always looking for ways to grow and enjoy the process.
          </p>
          <p>
            Feel free to explore my website, and I’d love to hear any suggestions for improvements or ideas on what you’d like to see here.
            If you’d like to chat or network, don’t hesitate to reach out!
          </p>
        </div>
      </div>
    </div>
  );
}
